.CategoryItem {
    display: flex;
    justify-content: center;
    padding-bottom: 24px;
}

.CategoryItem_ofPage_category {
    padding-bottom: 0;
}

.CategoryItem-Wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1272px;
}

.CategoryItem_theme_blackOrangeGradient .CategoryItem-Wrapper {
     max-width: 100%;
     padding: 0;
}

.MainPage-Content .CategoryItem_theme_dark .CategoryItem-Wrapper {
    height: 530px;
}

.CategoryItem-ContentWrap {
    /* width: 100%; */
    max-width: 1440px;
    margin: 0 auto 24px;
    position: relative;
}

.CategoryItem_ofPage_category .CategoryItem-ContentWrap {
    margin: 0 24px 24px;
}

.CategoryItem-HomeLink_ofPage_main {
    display: none;
}

.CategoryItem-Title {
    position: relative;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    color: var(--dark);
    margin: 24px;
}

.CategoryItem_ofPage_category .CategoryItem-Title {
    margin: 24px 24px 24px 60px;
}

.CategoryPage .CategoryItem-Title::before {
    display: flex;
}

.CategoryItem {
    background: var(--white);
 }

.CategoryItem_theme_lightBlue{
    background: var(--lightBlue);
}

.CategoryItem_theme_lightViolet{
    background: var(--lightViolet);
}

.CategoryItem_theme_lightRose{
    background: var(--lightRose);
}

.CategoryItem_theme_rose{
    background: var(--rose);
}

.CategoryItem_theme_lightGreen{
    background: var(--lightGreen);
}

.CategoryItem_theme_white{
    background: var(--white);
}


.CategoryItem-TitleLink {
    display: flex;
    justify-content: space-between;
    text-decoration: none;
    color: var(--dark);
}

.CategoryItem-MoreButtonLink {
    display: block;
    text-align: center;
    text-decoration: none;
    font-family: "Nunito Regular", Arial, sans-serif;
    font-weight: 700;
    font-size: 22px;
    color: inherit;
    position: relative;
}

.CategoryItem_theme_light .CategoryItem-MoreButtonLink {
    margin-bottom: 30px;
}


.CategoryItem-MoreButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 48px;
    margin: 0 auto;
    padding-left: 12px;
    border-radius: 24px;
    font-style: normal;
    font-size: 15px;
    line-height: 24px;
    text-align: center;
    box-shadow: 0 20px 32px 0 rgba(96, 97, 112, 0.24), 0 2px 8px 0 rgba(40, 41, 61, 0.08);
    cursor: pointer;
}

.CategoryItem-MoreButton img {
    margin-left: 12px;
    margin-top: 3px;
}

.CategoryItem .CategoryItem-MoreButton {
    background-color: var(--green);
    color: #fff;
}

/*категория на странице категорий */
.CategoryItem_ofPage_category {
    background-color: #f6f6f6;
}

.CategoryItem_ofPage_category.CategoryItem_theme_dark {
    background-color: #3B454E;
}

.CategoryItem_ofPage_category.CategoryItem_theme_dark .CategoryItem-HomeLink-Btn_size_S {
    /*filter: invert(100%);*/
}

.CategoryItem_ofPage_category.CategoryItem_theme_dark .CategoryItem-Title {
    color: #ffffff;
}

.CategoryItem-MoreButtonWrapper{
    margin: auto;
}

.CategoryItem-LinkWrap {
    width: 100%;
    max-width: 1440px;
    align-self: center;
    position: relative;
}

.CategoryItem_ofPage_category .CategoryItem-LinkWrap {
    display: none;
}

/*кнопка домой*/
.CategoryItem-HomeLink_ofPage_category {
    text-decoration: none;
    display: block;
    align-self: center;
    z-index: 1;
}

.CategoryItem-HomeLink-Btn_size_S {
    height: 30px;
    width: 30px;
    background-image: url('../Icons/backBlack.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px 20px;
    position: relative;
}

.CategoryItem_theme_dark .CategoryItem-HomeLink-Btn_size_S:nth-child(1) {
    background-image: url('../Icons/back.svg');
}

.MainPage-Content .CategoryItem-CategoryLink {
    height: 30px;
    width: 30px;
    background-image: url('../Icons/arrowMainPage.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 25px 25px;
    margin-left: 10px;
    margin: auto 0;

}

.CategoryItem:first-child .CategoryItem-CategoryLink {
    display: none;
}


@media (min-width: 720px) {
    .CategoryItem-Title  {
        font-size: 36px;
    }
}

@media (min-width: 1024px) {
    .CategoryItem_theme_blackOrangeGradient .CategoryItem-Wrapper {
        max-width: 1272px;
    }

    .CategoryItem-LinkWrap {
        max-width: 1272px;
    }

    .CategoryItem_ofPage_category .CategoryItem-LinkWrap {
        display: none;
    }

    .CategoryItem-Title {
        margin-left: 0;
        font-size: 44px;
        line-height: 48px;
        font-weight: 400;
    }

    .ContentListPartial-ItemsWrapper {
        padding: 0;
    }

    .CategoryItem_ofPage_category .CategoryItem-ContentWrap {
        margin: 24px 0;
    }

    .CategoryPage-Content.CategoryPage-Content_type_pressa .CategoryItem-Wrapper{
        max-width: unset;
    }

    .CategoryItem .CategoryItem-MoreButton:hover {
        background-color: var(--purple);
    }
}
