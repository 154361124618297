.ContentListFull {
}

.ContentListFull-ItemsWrapper {
    display: grid;
    margin: 0 auto;
    align-self: center;
    padding: 0;
    grid-gap: 24px;
    grid-template-columns: repeat(1, 100%);
    justify-content: center;
    grid-auto-flow: dense;
}

.ContentListFull-Item_type_music {
    margin-bottom: 10px;
    height: calc(100vw - 20px);
}

.swiper-scrollbar {
    margin: auto 0;
}

.CategoryPage .CategoryItem-MoreButton {
    display: none;
}

@media (min-width: 720px) {
    .ContentListFull-ItemsWrapper {
        grid-template-columns: repeat(2, calc(50% - 12px));
    }
}

@media (min-width: 1024px) {
    .ContentListFull-Item:nth-child(4n){
        display: none;
    }

    .ContentListFull-ItemsWrapper {
        grid-template-columns: repeat(3, calc(33% - 24px));
    }
}

@media (min-width: 1240px) {
    .ContentListFull-Item:nth-child(4n){
        display: block;
    }

    .ContentListFull-ItemsWrapper {
        grid-template-columns: repeat(4, calc(25% - 24px));
    }
}

