.PressContentPage {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(180deg, #111 0%, #383838 100%);
    position: relative;
}

.PressContentPage-Header{
    display: none;
}

.PressContentPage .preloader {
    height: 69vh;
}

@media(min-height: 800px) {
    .PressContentPage .preloader {
        height: 59vh;
    }
}

.PressContentPage-ContentWrapper {
    height: calc(100vh - 142px);
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.PressContentPage * {
    box-sizing: border-box;
}

.PressContentPage-Item {
    flex-grow: 1;
    display: flex;
    align-items: center;
    max-height: 70px;
    min-height: 40px;
    padding-right: 24px;
}

.PressContentPage-ItemTitleWrapper {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: var(--white);
    font-size: 20px;
    position: relative;
}

.PressContentPage-ItemDate {
    position: relative;
    white-space: nowrap;
    margin-left: 10px;
    font-weight: 400;
    font-size: 16px;
}

.PressContentPage-ItemTitle {
    font-size: 18px;
}

.PressContentPage-CategoryLink,
.PressContentPage-CategoryLink:hover,
.PressContentPage-CategoryLink:active {
    text-decoration: none;
    display: block;
    height: 30px;
    width: 30px;
    position: absolute;
    top: 50%;
    left: 24px;
    z-index: 1;
    transform: translateY(-50%);
    background-image: url('../../Icons/back.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px 20px;
}

.PressContentPage-SwiperWrapper {
    position: relative;
    top: 4%
}

@media(min-height: 800px) {
    .PressContentPage-SwiperWrapper {
        top: 10%
    }
}

.PressContentPage-Paginator {
    color: var(--white);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    font-weight: normal;
    font-size: 20px;
    margin: 0;
    height: auto;
    padding: 10px 20px 10px 5px;
    line-height: 30px;
    background: rgba(0, 0, 0, 0.8);
    padding-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;

}

.PressContentPage-CurrentPageInput {
    height: 30px;
    color: var(--white);
    background-color: transparent;
    text-align: center;
    display: inline-block;
    border-radius: 5px;
    font-size: inherit;
    font-family: inherit;
    outline: none;
    cursor: pointer;
}

.PressContentPage-PaginatorText {
    margin: 0 8px;
    opacity: 1;
}

.PressContentPage .slide {
    width: auto;
    height: calc(100% - 50px);
    min-height: calc(100% - 50px);
}

.PressContentPage .swiper-button-next,
.PressContentPage .swiper-button-prev {
    display: none;
    top: 50%;
    background-color: var(--white);
    background-size: 40%;
}

.PressContentPage .swiper-button-next {
    right: 250px;
}

.PressContentPage .swiper-button-prev {
    left: 250px;
}


.PressContentPage .swiper-container,
.PressContentPage .swiper-wrapper {
    min-height: 100%;
}

.PressContentPage-Image {
    display: block;
    max-width: 100vw;
    margin: 0 auto;
    width: auto;
    height: auto;
}

@media(min-width: 720px) {
    .PressContentPage {
        position: static;
    }

    .PressContentPage-Item {
        margin: 0 auto;
    }

    .PressContentPage-SwiperWrapper {
        position: static;
    }

    .PressContentPage-Image {
        max-width: 720px !important;
        height: auto;
        width: 720px;
    }

    .PressContentPage-ContentWrapper {
        margin-top: 10px;
    }

    .PressContentPage-CategoryLink,
    .PressContentPage-CategoryLink:hover,
    .PressContentPage-CategoryLink:active {
        top: -5px;
        left: unset;
        transform: unset;
        right: 120%;
    }

}

@media(min-width: 1024px) {
    /*.PressContentPage-SwiperWrapper {*/
    /*    flex-grow: 1;*/
    /*}*/
    /*.PressContentPage-ItemTitleWrapper{*/
    /*    justify-content: center;*/
    /*}*/

    .PressContentPage-SwiperWrapper {
        /*margin-left: 25%;*/
    }

    .PressContentPage .slide {
        /*width: auto !important;*/
        margin: auto;
    }

    .PressContentPage-Image {
        /*max-width: 820px !important;*/
        /*height: auto;*/
        /*width: 820px;*/
        height: calc(100vh - 134px);
        aspect-ratio: 0.762;
        max-width: unset !important;
        width: unset;
        /*margin: unset;*/
    }

    /*.PressContentPage .swiper-button-next,*/
    /*.PressContentPage .swiper-button-prev {*/
    /*    display: block;*/
    /*}*/
}
