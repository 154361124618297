.Equalizer {
    width: 18px;
    height: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Equalizer div {
    display: inline-block;
    background-color: var(--green);
    width: 4px;
    border-radius: 1px;
}

.Equalizer div:nth-child(1), .Equalizer div:nth-child(3) {
    /*background-color: var(--purple);*/
    -webkit-animation: .75s linear 300ms infinite normal equalizerPlay2;
            animation: .75s linear 300ms infinite normal equalizerPlay2;
}

.Equalizer div:nth-child(2) {
    /*background-color: var(--purple);*/
    -webkit-animation: .75s linear 300ms infinite normal equalizerPlay1;
            animation: .75s linear 300ms infinite normal equalizerPlay1;
}

.Equalizer div:hover {
    background-color: var(--purple);
}

.MusicItemPreview {
    width: 100%;
    box-shadow: 0 12px 36px 0 rgba(0, 0, 0, 0.16);
    padding: 24px;
    margin: 0 auto;
    border-radius: 6px;
    overflow: visible;
    background: #fff;
}

.MusicItemPreview-ImageWrapper {
    aspect-ratio: 1/1;
    width: 100%;
    position: relative;
    border-radius: 6px;
    cursor: pointer;
}

.MusicItemPreview-Image {
    height: 100%;
    width: 100%;
    border-radius: 6px;
}

.MusicItemPreview-Progress {
    position: absolute;
    top: 0;
    background: var(--green);
    width: 130px;
    height: 2px;
}

.MusicItemPreview-TrackInfo {
    display: flex;
    flex-direction: row;
    height: 100%;
}

.MusicItemPreview-TrackInfoWrapper {
    position: absolute;
    left: 50%;
    bottom: 12px;
    background: rgba(0, 0, 0, 0.5);
    -webkit-backdrop-filter: blur(4px);
            backdrop-filter: blur(4px);
    border-radius: 6px;
    width: calc(100% - 24px);
    height: 50px;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}

.MusicItemPreview-TrackInfoInner {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    position: relative;
    padding-left: 20px;
}

.MusicItemPreview-PlayerState {
    min-width: 60px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.MusicItemPreview-PlayerState > svg{
    color: var(--green);
}

.MusicItemPreview-TrackName {
    font-size: 16px;
    line-height: normal;
    margin-bottom: 2px;
    width: 100%;
    color: #fff;
}

.MusicItemPreview-TrackNameInner,
.MusicItemPreview-PlayerNameInner,
.MusicItemPreview-NextTrackNameInner,
.MusicItemPreview-NextPlayerNameInner
{
    display: inline-block;
    white-space: nowrap;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.MusicItemPreview-TrackNameInner.MusicItemPreview-TrackNameInner_isTicker,
.MusicItemPreview-PlayerNameInner.MusicItemPreview-PlayerNameInner_isTicker,
.MusicItemPreview-NextPlayerNameInner.MusicItemPreview-NextPlayerNameInner_isTicker,
.MusicItemPreview-NextTrackNameInner.MusicItemPreview-NextTrackNameInner_isTicker {
    -webkit-animation: ticker 5s infinite ease-in-out;
            animation: ticker 5s infinite ease-in-out;
}

.MusicItemPreview-PlayerName {
    color: #fff;
    font-size: 12px;
    line-height: normal;
    width: 100%;
}

.MusicItemPreview-NextTrackWrapper {
    margin-top: 12px;
    cursor: pointer;
    min-height: 60px;
}

.MusicItemPreview-NextTrackTitle {
    font-size: 12px;
    line-height: normal;
    font-weight: 400;
    color: #969696;
    margin: 0;
}

.MusicItemPreview-NextTrackInfo {
    color: #000;
    padding-top: 7px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    flex: 1 1;
}

.MusicItemPreview-NextTrackInfoWrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.MusicItemPreview-NextTrackInfoInner {
    display: flex;
    width: 100%;
}

.MusicItemPreview-NextTrackInfoInner.MusicItemPreview-NextTrackInfoInner_hidden {
    display: none;
}

.MusicItemPreview-NextTrackInfoText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10px;
    flex: 1 1;
    width: calc(100% - 60px);
    overflow: hidden;
}

.MusicItemPreview-Arrow {
    z-index: 1;
    position: absolute;
    right: 0;
}

.MusicItemPreview-NextTrackPreviewWrapper {
    height: 48px;
    width: 48px;
    border-radius: 6px;
    background-color: grey;
    position: relative;
}

.MusicItemPreview-NextTrackName {
    font-size: 16px;
    line-height: normal;
    margin-bottom: 3px;
    white-space: nowrap;
}

.MusicItemPreview-NextTrackNameInner {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
}

.MusicItemPreview-NextPlayerName {
    font-size: 12px;
    line-height: normal;
    white-space: nowrap;
    color: #969696;
}

@-webkit-keyframes ticker {
    0% {
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0);
    }
    100% {
        -webkit-transform: translate(-120%, 0);
                transform: translate(-120%, 0);
    }
}

@keyframes ticker {
    0% {
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0);
    }
    100% {
        -webkit-transform: translate(-120%, 0);
                transform: translate(-120%, 0);
    }
}

@media (min-width: 601px) {
    .MusicItemPreview {
        max-width: unset;
        width: 100%;
        height: 432px;
    }
    .MusicItemPreview-ImageWrapper {
        height: 304px;
    }
}

@media (min-width: 1200px) {
    .MusicItemPreview {
        height: 400px;
    }
    .MusicItemPreview-ImageWrapper {
        height: 280px;
    }

    .MusicItemPreview-PlayerState > svg:hover{
        color: var(--purple);
    }
}

.ContentDescription {
    position: relative;
    font-family: "Nunito Regular", Arial,sans-serif;
    color: #333333;
    max-height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.ContentDescription_size_small {
    color: #58595b;
    margin: 0;
    margin-top: -2px;
}

.ContentDescription-Title {
    color: #969696;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 40px;
    position: relative;
    font-family: "Nunito Regular", Arial, sans-serif;
}

.ContentDescription-Tags,
.ContentDescription-Type {
    font-family: "Nunito Regular", sans-serif;
    font-style: normal;
    font-weight: 700;
    height: 22px;
    margin-left: 0;
    margin-bottom: 8px;
    font-size: 18px;
    line-height: 22px;
    color: #000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ContentDescription-TitleText {
    font-family: 'Nunito Regular', sans-serif;
}

.ContentDescription-Type {
    display: none;
}

.ContentDescription_size_small .ContentDescription-LabelWrap {
    display: none;
}

.ContentDescription_size_small .ContentDescription-Title {
    height: 54px;
}

    /*стили для контента на странице контента*/
.ContentDescription_ofPage_ContentPage.ContentDescription_size_common .ContentDescription-Title{
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 20px;
}


@media (min-width: 375px) {
    .ContentDescription {
        width: 100%;
    }
}

.ContentItemPreview {
    border-radius: 6px;
    box-shadow: 0 12px 36px 0 rgba(0, 0, 0, 0.16);
    overflow: hidden;
}

.ContentItemPreview-ImageWrap {
    position: relative;
}

.ContentItemPreview-Link {
    padding: 24px;
    background: #fff;
}

.ContentItemPreview-Image {
    /*width: calc(100% - 96px);*/
    aspect-ratio: 1/1;
    border-radius: 6px;
    position: relative;
}
.ContentItemPreview-PlayIcon {
    width: 60px;
    height: 60px;
    border-radius: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-image: url(/static/media/play-button.8db9bc9d.svg);
    background-position: 20px 17px;
    background-repeat: no-repeat;
}

.ContentItemPreview-ButtonWrapper {
    position: absolute;
    bottom: 12px;
    width: calc(100% - 24px);
    left: 12px;
    display: flex;
    justify-content: space-between;
    z-index: 5;
}

.ContentItemPreview-ButtonWrapper_size_small {
    position: static;
    width: 100%;
    margin-top: 12px;
}

.ContentItemPreview-Cart {
     content: '';
     display: flex;
     width: 36px;
     height: 36px;
     background-color: var(--orange);
     border-radius: 6px;
     z-index: 5;
}

.ContentItemPreview-Cart > svg {
    margin: auto;
    color: var(--white)
}

.ContentItemPreview-Button {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    /*min-width: 140px;*/
    height: 36px;
    background-color: var(--green);
    /*background-image: url('../Icons/youtube-alt.svg');*/
    /*background-repeat: no-repeat;*/
    /*background-size: 26px;*/
    /*background-position: 6px center;*/
    color: #fff;
    font-size: 12px;
    line-height: 36px;
    border-radius: 6px;
    display: flex;
}

.ContentItemPreview-DescriptionWrap {
    flex-grow: 1;
}

.ContentItemPreview-ButtonText {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
    padding: 0 12px 0 36px;
    background-image: url(/static/media/youtube-alt.65871f86.svg);
    background-repeat: no-repeat;
    background-size: 26px;
    background-position: 6px center;
}

.ContentItemPreview-Button_type_read .ContentItemPreview-ButtonText {
    background-image: url(/static/media/file-alt.c78bc81e.svg);
}

/*стили для size_small*/

.ContentItemPreview_size_small {

}

.ContentItemPreview_size_small .ContentItemPreview-Link {
    padding: 12px;
    /*position: relative;*/
}

.ContentItemPreview_size_small .ContentItemPreview-Info {
    display: flex;
    overflow: hidden;
    position: relative;
}

.ContentItemPreview_size_small .ContentItemPreview-ImageWrap {
    width: 30%;
    min-width: 100px;
    min-height: 100px;
    margin-right: 12px;
    position: static;
}

.ContentItemPreview_size_small .ContentItemPreview-Button{
    flex-grow: 1;
}

.ContentItemPreview_size_small .ContentItemPreview-Cart {
    margin-left: 12px;
}

.ContentItemPreview_size_small .ContentItemPreview-Cart{
    right: 0;
    bottom: 0;
}

@media (min-width: 360px) {
    .ContentItemPreview_size_small .ContentItemPreview-Button{
        flex-grow: 1;
        left: 112px;
    }

    .ContentItemPreview_size_small .ContentItemPreview-Cart {
        margin-left: 12px;
    }

    .ContentItemPreview_size_small .ContentItemPreview-Button_type_read{
        width: 60%;
        display: flex;
        justify-content: center;
        background-position: 30% center;
    }
}

/*стили для странцы контента*/
.ContentItemPreview_size_common .ContentItemPreview-Info {
    display: flex;
    flex-direction: column;
}

.ContentItemPreview_size_common .ContentItemPreview-ImageWrap {
    order: 1;
}

@media (min-width: 1200px) {
    .ContentItemPreview:hover .ContentItemPreview-PlayIcon {
        background-color: var(--purple);
    }

    .ContentItemPreview:hover .ContentItemPreview-Cart {
        background-color: var(--redOrange);
    }

    .ContentItemPreview:hover .ContentItemPreview-Button {
        background-color: var(--purple);
    }
}

.ContentListFull {
}

.ContentListFull-ItemsWrapper {
    display: grid;
    margin: 0 auto;
    align-self: center;
    padding: 0;
    grid-gap: 24px;
    grid-template-columns: repeat(1, 100%);
    justify-content: center;
    grid-auto-flow: dense;
}

.ContentListFull-Item_type_music {
    margin-bottom: 10px;
    height: calc(100vw - 20px);
}

.swiper-scrollbar {
    margin: auto 0;
}

.CategoryPage .CategoryItem-MoreButton {
    display: none;
}

@media (min-width: 720px) {
    .ContentListFull-ItemsWrapper {
        grid-template-columns: repeat(2, calc(50% - 12px));
    }
}

@media (min-width: 1024px) {
    .ContentListFull-Item:nth-child(4n){
        display: none;
    }

    .ContentListFull-ItemsWrapper {
        grid-template-columns: repeat(3, calc(33% - 24px));
    }
}

@media (min-width: 1240px) {
    .ContentListFull-Item:nth-child(4n){
        display: block;
    }

    .ContentListFull-ItemsWrapper {
        grid-template-columns: repeat(4, calc(25% - 24px));
    }
}


.ContentListPartial {
    display: flex;
    justify-content: center;
}

.ContentListPartial-ItemsWrapper {
    display: grid;
    flex-direction: column;
    justify-content: center;
    grid-gap: 24px;
    padding: 0 24px;
    width: 100%;
    grid-template-columns: repeat(1, 100%);
    grid-template-rows: repeat(8, auto);
}

.ContentListPartial-Item_type_music.ContentListPartial-Item_size_big {
    margin-bottom: 10px;
    height: calc(100vw - 20px);
}

@media (min-width: 720px) {
    .ContentListPartial-ItemsWrapper {
        grid-template-columns: repeat(2, calc(50% - 12px));
        grid-template-rows: repeat(4, auto);
    }
}

@media (min-width: 1024px) {
    .ContentListPartial-Item:nth-child(4n){
        display: none;
    }

    /*.ContentListPartial-Item_size_small:nth-child(8){*/
    /*    display: none;*/
    /*}*/

    .ContentListPartial-ItemsWrapper {
        grid-template-columns: repeat(3, calc(33% - 24px));
        grid-template-rows: repeat(2, auto);
    }
}

@media (min-width: 1240px) {
    .ContentListPartial-Item:nth-child(4n){
        display: block;
    }

    /*.ContentListPartial-Item_size_small:nth-child(8){*/
    /*    display: flex;*/
    /*}*/

    .ContentListPartial-ItemsWrapper {
        grid-template-columns: repeat(4, calc(25% - 24px));
        grid-template-rows: repeat(2, auto);
    }
}

/*@media (max-width: 1200px) {*/
/*    .ContentListPartial-ItemsWrapper {*/
/*        grid-template-columns: repeat(2, minmax(200px, 348px));*/
/*    }*/

/*    .ContentListPartial-Item_type_music.ContentListPartial-Item_size_big {*/
/*        grid-column: span 1;*/
/*        grid-row: span 2;*/
/*        margin-bottom: 0;*/
/*        height: auto;*/
/*    }*/
/*}*/



/*@media (min-width: 600px) {*/
/*    .ContentListPartial-ItemsWrapper {*/
/*        grid-template-columns: repeat(4, minmax(200px, 320px));*/
/*        grid-template-rows: repeat(3, auto);*/
/*    }*/
/*}*/

.PressContentList {
    background-color: var(--lightGrey);
}

.PressContentList-Item {
    display: flex;
    flex-direction: column;
}

.PressContentList-Wrapper {
    width: 100%;
    margin: auto;
}

.PressContentList-ItemTitle {
    max-width: calc(100% - 40px);
    display: inline-block;
    font-size: 26px;
    line-height: 1;
    padding: 0;
    text-align: start;
    font-family: "Nunito Regular", Arial, sans-serif;
    font-weight: 400;
    /*max-width: 745px;*/
    color: var(--dark);
    margin: 24px 24px;
}

.PressContentList-ItemTitle span {
    display: block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-height: 56px;
    padding-bottom: 10px;
}

@media (min-width: 720px) {
    .PressContentList {
        margin-top: 30px;
    }

    .PressContentList-ItemTitle {
        font-size: 36px;
    }

}


@media (min-width: 1024px) {

    .PressContentList-Wrapper {
        width: 1272px;

    }

    .PressContentList-ItemTitle {
        font-size: 44px;
        line-height: 48px;
        margin-left: 0;
    }
}


.PressContentItem {
    text-decoration: none;
    color: inherit;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.PressContentItem_isSlide {
    padding: 0 12px;
}

.PressContentItem-ImageWrap {
    width: 100%;
    aspect-ratio: 0.765;
    height: auto;
    position: relative;
    overflow: hidden;
    display: inline-block;
    border-radius: 12px;
}

.swiper-container {
    max-width: 1600px;
}

.PressContentItem-Image {
    border-radius: 2px;
    position: absolute;
    display: block;
    width: 100%;
    /*height: 262px;*/
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}


.PressContentItem-Title {
    text-align: center;
    margin: 12px 0;
    position: relative;
    font-size: 16px;
    line-height: normal;
    text-transform: uppercase;
    color: var(--grey);
}

/*.PressContentSlider .swiper-slide-active .PressContentItem-Title {*/
/*    color: var(--grey);*/
/*}*/

.PressContentItem-Cart {
    position: absolute;
    right: 12px;
    bottom: 12px;
    content: '';
    display: flex;
    width: 36px;
    height: 36px;
    background-color: var(--orange);
    border-radius: 6px;
    z-index: 5;
}

.PressContentItem-Cart > svg {
    margin: auto;
    color: var(--white)
}

.PressContentSlider .swiper-slide-active .PressContentItem-Cart{
    background-color: var(--redOrange);
}

@media (max-width: 800px) {
    .PressContentItem {
        /* width: 144px !important; */
        /* margin-right: 10px !important; */
    }

    .swiper-container {
        /* width: 100%; */
    }

    /*.PressContentItem-Image,*/
    /*.PressContentItem-ImageWrap {*/
    /*    width: 144px;*/
    /*    height: 188px;*/
    /*}*/
}

@media (max-width: 600px) {

    .PressContentItem {
        /* width: 130px !important; */
    }

    /*.PressContentItem-Image,*/
    /*.PressContentItem-ImageWrap {*/
    /*    width: 130px;*/
    /*    height: 170px;*/
    /*}*/
}


@media (min-width: 1024px) {

    .PressContentItem-ImageWrap {
        height: 262px;
        width: 200px;
    }

    .PressContentItem .PressContentItem-ImageWrap:before {
        width: 35px;
        height: 35px;
    }

    .PressContentSlider .swiper-slide-active .PressContentItem-ImageWrap {
        /*box-shadow: 0 12px 36px 0 rgba(255, 169, 0, 0.4);*/
        box-shadow: none;
        -webkit-transition: all ease-in 0.3s;
        transition: all ease-in 0.3s;
    }

    .PressContentSlider .swiper-slide:hover .PressContentItem-ImageWrap {
        /*box-shadow: 0 12px 36px 0 rgba(255, 169, 0, 0.4);*/
        -webkit-transform: scale(1.03);
                transform: scale(1.03);
        -webkit-transition: all ease-in 0.3s;
        transition: all ease-in 0.3s;
    }

    .PressContentSlider .swiper-slide-active .PressContentItem-Cart{
        background-color: var(--orange);
        -webkit-transition: all ease-in 0.3s;
        transition: all ease-in 0.3s;
    }

    /*.PressContentSlider .swiper-slide-active .PressContentItem-Cart > svg{*/
    /*    color: #FF6600;*/
    /*    transition: all ease-in 0.3s;*/
    /*}*/

    .PressContentSlider .swiper-slide:hover .PressContentItem-Cart{
        background-color: var(--redOrange);
        -webkit-transition: all ease-in 0.3s;
        transition: all ease-in 0.3s;
    }

    /*.PressContentSlider .swiper-slide:hover .PressContentItem-Cart > svg{*/
    /*    color: #FFDE00;*/
    /*    transition: all ease-in 0.3s;*/
    /*}*/

    /*.PressContentSlider .swiper-slide-active .PressContentItem-Title {*/
    /*    color: #FFF;*/
    /*    transition: all ease-in 0.3s;*/
    /*}*/
    /*.PressContentSlider .swiper-slide:hover .PressContentItem-Title {*/
    /*    color: #FF6600;*/
    /*    transition: all ease-in 0.3s;*/
    /*}*/
}


.PressContentBlock {
    display: grid;
    grid-row-gap: 12px;
    grid-column-gap: 12px;
    padding: 0 12px;
    grid-template-columns: repeat(2, calc(50% - 6px));
}

.PressContentBlock-MoreButton {
    height: 48px;
    width: 180px;
    background-color: var(--green);
    color: var(--white);
    font-size: 16px;
    font-weight: 700;
    line-height: 48px;
    border-radius: 24px;
    margin: 12px auto 24px;
    padding-left: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.PressContentBlock-MoreButton img {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    margin-left: 12px;
    margin-top: 5px;
}

.PressContentBlock-MoreButton_isMore img {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

@media (min-width: 720px) {
    .PressContentBlock {
        grid-row-gap: 24px;
        grid-column-gap: 24px;
        padding: 0 24px;
        grid-template-columns: repeat(3, calc(100%/3 - 16px));
    }

    .PressContentBlock-MoreButton {
        margin-bottom: 36px;
    }
}

@media (min-width: 1024px) {
    .PressContentBlock {
        padding: 0;
        grid-column-gap: 12px;
        grid-template-columns: repeat(6, calc(100%/6 - 8px));
    }

    .PressContentList-Wrapper {
        display: flex;
        flex-direction: column;
        align-items: start;
    }

    .PressContentBlock-MoreButton {
        margin-left: 0;
        margin-right: 0;
        align-self: end;
    }

    .PressContentBlock-MoreButton:hover {
        background-color: var(--purple);
    }

}

.PressContentSlider-ContentItem {

}

.PressContentSlider .swiper-button-prev,
.PressContentSlider .swiper-button-next {
    display: none;
    background-color: var(--white);
    background-size: 50%;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
    z-index: 100000000;
}
.PressContentSlider .swiper-button-next.swiper-button-disabled,
.PressContentSlider .swiper-button-prev.swiper-button-disabled {
    opacity: 0;
}

/*.PressContentSlider .swiper-button-prev::before,*/
/*.PressContentSlider .swiper-button-next::before {*/
/*    display: block;*/
/*    content: '';*/
/*    width: 500px;*/
/*    height: 350px;*/
/*    background: linear-gradient(90deg, #3B454E 0%, rgba(59, 69, 78, 0) 70%);*/
/*    transform: matrix(-1, 0, 0, 1, 0, 0) translateZ(-1px);*/
/*    pointer-events: none;*/
/*    position: absolute;*/
/*    bottom: -130px;*/
/*    right: -100px;*/
/*}*/

.PressContentSlider-blackout-next {
     -webkit-transform: matrix(1, 0, 0, 1, 0, 0);
             transform: matrix(1, 0, 0, 1, 0, 0);
     right: 20px;
}

.PressContentSlider .swiper-slide img {
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
}

.PressContentSlider .swiper-slide-active img {
    -webkit-filter: none;
            filter: none;
}

.PressContentSlider .swiper-container {
    padding-bottom: 20px;
    margin-bottom: 24px;
}


.PressContentSlider .swiper-pagination {
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.PressContentSlider .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background-color: var(--green);
    opacity: 1;
}

.PressContentSlider .swiper-pagination-bullet-active {
    background-color: transparent;
    width: 18px;
    height: 18px;
    border: 3px solid var(--purple);
}

/*.swiper-slide {*/
/*    width: auto !important;*/
/*    margin-right: 10px !important;*/
/*}*/

.PressContentPage .swiper-slide {
    width: 100% !important;
}

@media (min-width: 1200px) {

}

@media (min-width: 1200px) {
    .PressContentSlider {
        max-width: 1272px;
        margin: 0 auto;
        padding-top: 20px;
    }

    .PressContentSlider .swiper-wrapper {
        padding-top: 20px;
    }

    .PressContentSlider .swiper-slide-active img {
        -webkit-filter: grayscale(100%);
                filter: grayscale(100%);
        -webkit-transition: all ease-in 0.3s;
        transition: all ease-in 0.3s;
        box-shadow: none;
    }

    .PressContentSlider .swiper-slide:hover img {
        -webkit-filter: none;
                filter: none;
        -webkit-transition: all ease-in 0.3s;
        transition: all ease-in 0.3s;
    }

    .PressContentSlider .swiper-pagination {
        display: none;
    }

    .swiper-button-prev::before,
    .swiper-button-next::before {
        display: block;
    }

    .PressContentSlider .swiper-button-prev,
    .PressContentSlider .swiper-button-next {
        display: block;
        -webkit-transform-style: preserve-3d;
                transform-style: preserve-3d;

    }
    .CategoryPage-Content .swiper-button-prev {
        background: transparent;
    }
    .CategoryPage-Content .swiper-button-prev::before,
    .CategoryPage-Content .swiper-button-next::before {
        height: 320px;
        bottom: -130px;
        background: -webkit-gradient(linear, left top, right top, from(#3B454E), to(rgba(59, 69, 78, 0)));
        background: -webkit-linear-gradient(left, #3B454E 0%, rgba(59, 69, 78, 0) 100%);
        background: linear-gradient(90deg, #3B454E 0%, rgba(59, 69, 78, 0) 100%);
        -webkit-transform: matrix(-1, 0, 0, 1, 0, 0);
                transform: matrix(-1, 0, 0, 1, 0, 0);
        -webkit-transform: matrix(-1, 0, 0, 1, 0, 0) translateZ(-1px);
                transform: matrix(-1, 0, 0, 1, 0, 0) translateZ(-1px);

    }

}

.CategoryItem {
    display: flex;
    justify-content: center;
    padding-bottom: 24px;
}

.CategoryItem_ofPage_category {
    padding-bottom: 0;
}

.CategoryItem-Wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1272px;
}

.CategoryItem_theme_blackOrangeGradient .CategoryItem-Wrapper {
     max-width: 100%;
     padding: 0;
}

.MainPage-Content .CategoryItem_theme_dark .CategoryItem-Wrapper {
    height: 530px;
}

.CategoryItem-ContentWrap {
    /* width: 100%; */
    max-width: 1440px;
    margin: 0 auto 24px;
    position: relative;
}

.CategoryItem_ofPage_category .CategoryItem-ContentWrap {
    margin: 0 24px 24px;
}

.CategoryItem-HomeLink_ofPage_main {
    display: none;
}

.CategoryItem-Title {
    position: relative;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    color: var(--dark);
    margin: 24px;
}

.CategoryItem_ofPage_category .CategoryItem-Title {
    margin: 24px 24px 24px 60px;
}

.CategoryPage .CategoryItem-Title::before {
    display: flex;
}

.CategoryItem {
    background: var(--white);
 }

.CategoryItem_theme_lightBlue{
    background: var(--lightBlue);
}

.CategoryItem_theme_lightViolet{
    background: var(--lightViolet);
}

.CategoryItem_theme_lightRose{
    background: var(--lightRose);
}

.CategoryItem_theme_rose{
    background: var(--rose);
}

.CategoryItem_theme_lightGreen{
    background: var(--lightGreen);
}

.CategoryItem_theme_white{
    background: var(--white);
}


.CategoryItem-TitleLink {
    display: flex;
    justify-content: space-between;
    text-decoration: none;
    color: var(--dark);
}

.CategoryItem-MoreButtonLink {
    display: block;
    text-align: center;
    text-decoration: none;
    font-family: "Nunito Regular", Arial, sans-serif;
    font-weight: 700;
    font-size: 22px;
    color: inherit;
    position: relative;
}

.CategoryItem_theme_light .CategoryItem-MoreButtonLink {
    margin-bottom: 30px;
}


.CategoryItem-MoreButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 48px;
    margin: 0 auto;
    padding-left: 12px;
    border-radius: 24px;
    font-style: normal;
    font-size: 15px;
    line-height: 24px;
    text-align: center;
    box-shadow: 0 20px 32px 0 rgba(96, 97, 112, 0.24), 0 2px 8px 0 rgba(40, 41, 61, 0.08);
    cursor: pointer;
}

.CategoryItem-MoreButton img {
    margin-left: 12px;
    margin-top: 3px;
}

.CategoryItem .CategoryItem-MoreButton {
    background-color: var(--green);
    color: #fff;
}

/*категория на странице категорий */
.CategoryItem_ofPage_category {
    background-color: #f6f6f6;
}

.CategoryItem_ofPage_category.CategoryItem_theme_dark {
    background-color: #3B454E;
}

.CategoryItem_ofPage_category.CategoryItem_theme_dark .CategoryItem-HomeLink-Btn_size_S {
    /*filter: invert(100%);*/
}

.CategoryItem_ofPage_category.CategoryItem_theme_dark .CategoryItem-Title {
    color: #ffffff;
}

.CategoryItem-MoreButtonWrapper{
    margin: auto;
}

.CategoryItem-LinkWrap {
    width: 100%;
    max-width: 1440px;
    align-self: center;
    position: relative;
}

.CategoryItem_ofPage_category .CategoryItem-LinkWrap {
    display: none;
}

/*кнопка домой*/
.CategoryItem-HomeLink_ofPage_category {
    text-decoration: none;
    display: block;
    align-self: center;
    z-index: 1;
}

.CategoryItem-HomeLink-Btn_size_S {
    height: 30px;
    width: 30px;
    background-image: url(/static/media/backBlack.6375d4c1.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px 20px;
    position: relative;
}

.CategoryItem_theme_dark .CategoryItem-HomeLink-Btn_size_S:nth-child(1) {
    background-image: url(/static/media/back.12097767.svg);
}

.MainPage-Content .CategoryItem-CategoryLink {
    height: 30px;
    width: 30px;
    background-image: url(/static/media/arrowMainPage.920cd8c7.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 25px 25px;
    margin-left: 10px;
    margin: auto 0;

}

.CategoryItem:first-child .CategoryItem-CategoryLink {
    display: none;
}


@media (min-width: 720px) {
    .CategoryItem-Title  {
        font-size: 36px;
    }
}

@media (min-width: 1024px) {
    .CategoryItem_theme_blackOrangeGradient .CategoryItem-Wrapper {
        max-width: 1272px;
    }

    .CategoryItem-LinkWrap {
        max-width: 1272px;
    }

    .CategoryItem_ofPage_category .CategoryItem-LinkWrap {
        display: none;
    }

    .CategoryItem-Title {
        margin-left: 0;
        font-size: 44px;
        line-height: 48px;
        font-weight: 400;
    }

    .ContentListPartial-ItemsWrapper {
        padding: 0;
    }

    .CategoryItem_ofPage_category .CategoryItem-ContentWrap {
        margin: 24px 0;
    }

    .CategoryPage-Content.CategoryPage-Content_type_pressa .CategoryItem-Wrapper{
        max-width: unset;
    }

    .CategoryItem .CategoryItem-MoreButton:hover {
        background-color: var(--purple);
    }
}

.CloseButton {
    cursor: pointer;
}
.HamburgerButton {
    cursor: pointer;
}
.Menu {
    min-width: 320px;
    font-family: "Nunito Semibold", Arial, sans-serif;
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    width: 100%;
    margin-top: 84px;
    color: var(--white);
    min-height: 84px;
}

.Menu-Wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.Menu_visible {
    display: block;
}

.Menu-List {
    width: 100%;
    height: 100%;
    list-style: none;
    margin: 0;
    padding: 40px 0;
    background-color: var(--green);
}

.Menu-Link,
.Menu-Link:hover,
.Menu-Link:active {
    display: flex;
    justify-content: center;
    text-decoration: none;
    text-align: left;
    height: 48px;
    line-height: 48px;
    color: currentColor;
    position: relative;
}

/*.Menu-Item:before {*/
    /*position: absolute;*/
    /*top: 50%;*/
    /*transform: translateY(-50%);*/
    /*left: 0;*/
    /*content: '';*/
    /*display: block;*/
    /*width: 25px;*/
    /*height: 25px;*/
    /*background-position: center;*/
    /*background-repeat: no-repeat;*/
    /*background-size: contain;*/
/*}*/


@media (min-width: 720px) {
    .Menu {
        font-size: 24px;
    }

    .Menu-Link,
    .Menu-Link:hover,
    .Menu-Link:active {
        line-height: 90px;
        height: 90px;
    }
}


@media (min-width: 1200px) {
    .Menu {
        width: 100%;
        margin-top: 0;
        font-weight: 400;
        font-size: 14px;
    }

    .Menu-Wrapper {
        width: 900px;
        flex-direction: row;
        background-color: transparent;
        display: flex;
        align-items: center;
    }

    .Menu-List {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin: 0;
        padding: 0;
        height: 36px;
        background-color: transparent;
    }

    .Menu-Item {
        flex-grow: 1;
        padding: 0 15px;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .Menu-ItemLink {
        height: unset;
        line-height: unset;
        display: flex;
    }
    .Menu-ItemLink div {
        margin: auto;
    }

    .Menu-Item_active .Menu-ItemLink {
        margin: auto;
        padding: 4px 12px;
        border-radius: 18px;
        background-color: var(--purple);
        color: var(--white);
    }

    .Menu-Item_active .Menu-ItemLink div{
        /*color: var(--white);*/
    }

    .Menu-Item:before {
        display: none;
    }

    .Menu-Link,
    .Menu-Link:hover,
    .Menu-Link:active {
        color: var(--grey);
        justify-content: flex-end;
        height: unset;
        line-height: unset;
        /*height: 84px;*/
        /*line-height: 84px;*/
    }

    .Menu-Link:hover {
        color: var(--green);
    }

    .Menu-Item_active .Menu-Link,
    .Menu-Item_active .Menu-Link:hover,
    .Menu-Item_active .Menu-Link:active {
        color: var(--white);
    }


    .Menu-Item_active .Menu-ItemLink:hover {
        line-height: inherit;
    }

    .Menu-ItemLink-Text {
        font-style: normal;
        font-size: 14px;
        text-transform: none;
        /*line-height: 50px;*/
        text-align: center;
    }
}

.Header {
    background-color: var(--white);
    position: fixed;
    z-index: 99;
    width: 100%;
    /*height: 93px;*/
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 84px;
    -webkit-transition: background-color ease-in 0.5s;
    transition: background-color ease-in 0.5s;
}

.Header_ofPage_ContentPage {
    background-color: var(--white);
    -webkit-transition: background-color ease-in 0.2s;
    transition: background-color ease-in 0.2s;
}

.Header_visible {
    background-color: var(--green);
}

.Header-Wrapper {
    display: flex;
    justify-content: space-between;
    position: relative;
    align-items: center;
    width: 100%;
    max-width: 1272px;
}

/*.Header-Logo {*/
/*    height: 16px;*/
/*    max-height: 36px;*/
/*    filter: grayscale(100%);*/
/*}*/

.Header-LogoContainer {
    margin-left: 24px;
}

.Header-LogoContainer svg{
    height: 16px;
    width: 132px;
    color: var(--dark);
}

.Header_visible .Header-LogoContainer svg{
    height: 16px;
    color: var(--white);
}

.Header-Title {
    position: relative;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    color: var(--dark);
    margin: 24px;
}

.Header-CategoryLinkWrap {
    margin: 0 24px;
}

.Header-HomeLinkWrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 24px;
}

.Header-CategoryLinkWrap .Header-Title:before {
    fill: #fff;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    left: -30px;
    content: '';
    width: 17px;
    height: 17px;
    background-image: url(/static/media/backBtn.e2925df8.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.Header-ButtonWrapper {
    position: relative;
    height: 84px;
    width: 50px;
    margin-right: 24px;
}

.Header-Select {
    cursor: pointer;
    position: relative;
    margin-left: auto;
    width: 50px;
}

.Header-SelectList {
   display: none;
    padding: 0;
    list-style-type: none;
}

.Header-SelectList_visible {
    position: absolute;
    top: 100%;
    display: block;
    margin: 0;
    background-color: var(--white);
    width: 40px;
}

.Header-SelectItem {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    height: 30px;
    line-height: 30px;
    text-align: center;
    padding: 0 30px 10px 0;
    width: 60px;
}

.Header-SelectItem span {
    font-size: 16px;
}

.Header-SelectItem_state_selected {
    background-image: url(/static/media/down-arrow.2cad92c1.svg);
    background-repeat: no-repeat;
    background-position: 30px 13px;
    background-size: 10px;
}

.Header-SelectItem_active {
    display: none;
}


.Header-HamburgerButton,
.Header-CloseButton {
    display: block;
    position: absolute;
    padding: 25px 0 25px 25px;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    z-index: 10;
    opacity: 0;
    -webkit-transition:  all cubic-bezier(0.17,0.67,1,-0.1) 0.5s;
    transition:  all cubic-bezier(0.17,0.67,1,-0.1) 0.5s;
}

.Header_ofPage_ContentPage .Header-HamburgerButton {
    -webkit-filter: invert(30%);
            filter: invert(30%);
}

.Header-HamburgerButton_visible,
.Header-CloseButton_visible {
    opacity: 1;
}

.Header-Menu {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    height: 100vh;
    min-height: 100%;
    z-index: 1;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    opacity: 0;
    -webkit-transition: opacity ease-in-out 0.8s, -webkit-transform ease-in-out 0.2s;
    transition: opacity ease-in-out 0.8s, -webkit-transform ease-in-out 0.2s;
    transition: opacity ease-in-out 0.8s, transform ease-in-out 0.2s;
    transition: opacity ease-in-out 0.8s, transform ease-in-out 0.2s, -webkit-transform ease-in-out 0.2s;
}

.Header-Menu_visible {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
    width: 100%;
}

@media (min-width: 1200px) {
    .Header-Menu,
    .Header-Menu_visible {
        display: flex;
        justify-content: flex-end;
        position: relative;
        -webkit-transform: translateX(0);
                transform: translateX(0);
        height: 84px;
        top: auto;
        bottom: auto;
        left: 0;
        opacity: 1;
    }

    .Header-ButtonWrapper {
        display: none;
    }

    .Header-LogoContainer {
        margin-left: 0;
    }

    .Header-LogoContainer svg{
        height: 24px;
        width: 180px;
        color: var(--green);
    }
}

.CategoryPage {
}

.CategoryPage .CategoryItem {
    background-color: transparent;
}

.CategoryPage-Content {
    padding-top: 84px;
}

/*.CategoryPage-Content:before {*/
/*    position: absolute;*/
/*    display: block;*/
/*    content: '';*/
/*    height: 216px;*/
/*    width: 100%;*/
/*    top: 0;*/
/*    left: 0;*/
/*    background-repeat: no-repeat;*/
/*    background-position: 50% 0;*/
/*    background-size: 100% auto;*/
/*}*/

/*.CategoryPage-Content_type_pressa.CategoryPage-Content:before {*/
/*    background-image: url("../Images/press.jpg");*/
/*}*/

/*.CategoryPage-Content_type_sport.CategoryPage-Content:before {*/
/*    background-image: url("../Images/sport.jpg");*/
/*}*/

/*.CategoryPage-Content_type_avto.CategoryPage-Content:before {*/
/*    background-image: url("../Images/auto.jpg");*/
/*}*/

/*.CategoryPage-Content_type_stil.CategoryPage-Content:before {*/
/*    background-image: url("../Images/style.jpg");*/
/*}*/

/*.CategoryPage-Content_type_gadzheti.CategoryPage-Content:before {*/
/*    background-image: url("../Images/gadgets.jpg");*/
/*}*/

/*.CategoryPage-Content_type_otdikh.CategoryPage-Content:before {*/
/*    background-image: url("../Images/rest.jpg");*/
/*}*/

/*.CategoryPage-Content_type_kosmos.CategoryPage-Content:before {*/
/*    background-image: url("../Images/space.jpg");*/
/*}*/

/*.CategoryPage-Content_type_pitanie.CategoryPage-Content:before {*/
/*    background-image: url("../Images/food.jpg");*/
/*}*/

@media (min-width: 720px) {
    .CategoryPage-Content:before {
        background-position: 50% -130px;
    }
}

@media (min-width: 1024px) {
    .CategoryPage-Content:before {
        background-position: 50% -650px;
    }
}

.ContentAdditional {
    color: #000;
    flex-direction: column;
    margin: auto;
    display: grid;
    grid-gap: 24px;
    grid-template-columns: repeat(1, auto);
    margin-bottom: 24px;
}

.ContentAdditional_theme_dark {
    color: #fff;
}


/*@media (max-width: 800px) {*/

/*    .ContentAdditional {*/
/*        !*grid-auto-flow: dense;*!*/
/*    }*/

/*}*/

@media (min-width: 720px) {
    .ContentAdditional {
        grid-template-columns: repeat(2, auto);
    }
}

@media (min-width: 1024px) {
    .ContentAdditional {
        margin: 0;
        grid-template-columns: repeat(4, auto);
    }

    .ContentPage_page_text .ContentAdditional {
        grid-template-columns: repeat(1, auto);
    }


}

.ContentText {
    text-align: justify;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    margin-bottom: 20px;
}

.ContentText p,
.ContentText ul {
}

.ContentText p:first-child {
    font-family: "Nunito Regular", Arial, sans-serif;
}

.ContentText h2, h3 {
    font-family: "Nunito Regular", Arial, sans-serif;
    font-size: 24px;
    line-height: normal;
}

.ContentText h3 {
    font-size: 20px;
}

.ContentText h1,
.ContentText h2,
.ContentText h3,
.ContentText h4,
.ContentText h5 {
    text-align: left;
}

.ContentText li {
    list-style: none;
    position: relative;
    padding-left: 20px;
    padding-bottom: 10px;
    text-align: left;
}

.ContentText li:before {
    display: block;
    content: '';
    width: 6px;
    height: 6px;
    position: absolute;
    top: 10px;
    left: -5px;
    border-radius: 3px;
    background-color: #f5d300;
}

.ContentText p,
.ContentText i {
    margin-bottom: 1em;
    display: block;
}

.ContentText img,
.ContentText video {
    border-radius: 24px;
    display: block;
    max-width: 100%;
    height: auto;
    margin-top: 20px;
    width: 100%;
}

.ContentText p img {
    margin-bottom: 20px;
}

.ContentText video {
    margin-bottom: 20px;
}


@media (min-width: 720px) {
    .ContentText {
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 40px;
    }

    .ContentText p,
    .ContentText ul {
        font-family: inherit;
    }
}

.ErrorPage {
    text-align: center;
    height: 85vh;
    margin-bottom: 1px;
}

.ErrorPage-LogoContainer {
    background-color: var(--white);
    height: 70px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    display: flex;
    align-items: center;
}

.ErrorPage-LogoContainer {
    padding-left: 20px;
}

.ErrorPage-Logo {
    max-height: 40px;
}


.ErrorPage-Title {
    margin: 30vh 0 20px;
    text-align: center;
}

.ErrorPage-Title span {
    display: block;
}

.ErrorPage-Link,
.ErrorPage-Link:hover,
.ErrorPage-Link_active {
    display: block;
    text-decoration: none;
    width: 170px;
    font-size: 24px;
    line-height: 50px;
    background-color: var(--green);
    background-image: none;
    border-radius: 25px;
    text-align: center;
    margin: 0 auto 100px;
    color: #4c4c4e;
}

@media (min-width: 720px) {
    .ErrorPage {
        font-family: "Nunito Regular", Arial, sans-serif;
    }

    .ErrorPage * {
        font-family: inherit;
    }

}

.preloader {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 120px;
    padding-bottom: 50px;
    z-index: 10000;
    box-sizing: border-box;
}

.preloader--slider-item {
    padding-top: 43vh;
}

.preloader--fullscreen {
    position: fixed;
    padding: 0;
    background-color: var(--white);
}

.preloader--vertical {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.ContentPage {
    display: flex;
    justify-content: center;
    padding: 0;
}

.ContentPage-Content {
    margin-top: 84px;
    padding-top: 24px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 1440px;
    width: 100%;
}

.ContentPage-Wrapper {
    width: 100%;
    max-width: 1272px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}

.ContentPage-CategoryLink-Btn {
    text-decoration: none;
    display: block;
    height: 30px;
    width: 30px;
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 30px 30px;
}

.ContentPage-Video {
    max-width: 100%;
    margin-bottom: 20px;
    width: 100%;
}

.ContentPage-Video[poster] {
    object-fit: cover;
}

.ContentPage-ContentAdditionalWrapper {
    display: flex;
    flex-direction: column;
    margin: 0 24px;
}

.ContentPage-ContentAdditionalTitle {
    font-family: "Nunito Bold", sans-serif;
    font-weight: 700;
    color: var(--black);
    text-align: left;
    margin: 24px 0;
}

.ContentPage-Title {
    position: relative;
    margin-left: 11px;
    font-family: "Nunito Bold", Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
    color: var(--black);
    display: flex;
    flex-direction: row;
}

.ContentPage-Image {
    border-radius: 24px;
    width: 100%;
    aspect-ratio: 1 / 1;
    margin-bottom: 24px;
}

.ContentPage-ContentName {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 1.2;
    color: var(--black);
    margin-bottom: 24px;
}

.ContentPage-Title:before {
    display: none;
}

.ContentPage-MainBar {
    width: 100%;
}

.ContentPage-ContentWrap{
    box-shadow: none;
    margin: 0;
    padding: 0 24px;
}

.ContentPage-CategoryLink {
    height: 48px;
    width: calc(100% - 48px);
    color: var(--white);
    font-size: 16px;
    font-weight: 700;
    line-height: 48px;
    background-color: var(--green);
    border-radius: 24px;
    margin: 12px auto 24px;
    padding-right: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
}

.ContentPage-CategoryLink img {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    margin-right: 12px;
}

.ContentPage-AdditionalBar {
    margin-top: 0;
    max-width: 100%;
    justify-content: center;
    display: flex;
    border-top: 1px solid #969696;
}

.ContentPage-AdditionalBar.ContentPage-AdditionalBar_theme_dark {
    border-top: none;
}

@media (max-width: 374px) {
    .ContentPage-CategoryLink {
        font-size: 13px;
    }
}

/*@media (max-width: 800px) {*/
/*    .ContentPage-Wrapper {*/
/*        width: 100%;*/
/*        flex-direction: column;*/
/*    }*/

/*    .ContentPage-Title {*/
/*        margin-left: -8px;*/
/*    }*/

/*    .ContentPage-ContentWrap {*/
/*        width: auto;*/
/*    }*/
/*}*/

@media (min-width: 720px) {
    .ContentPage_page_text .ContentPage-TitleWrap {
        position: relative;
    }

    .ContentPage_page_text .ContentPage-ContentName {
        color: #EEEEEE;
        position: absolute;
        bottom: 24px;
        left: 24px;
        width: calc(100% - 248px);
        font-weight: 700;
        font-size: 36px;
        margin-bottom: 0;
    }
}

@media (min-width: 1024px) {
    .ContentPage {
        margin-bottom: 24px;
    }

    .ContentPage-Wrapper {
        flex-direction: row;
        width: calc(100% - 48px);
    }

    .ContentPage_page_text {
        background-color: #EEEEEE;
    }

    .ContentPage_page_text .ContentPage-MainBar {
        background-color: var(--white);
        margin-right: 96px;
        margin-bottom: 24px;
        padding: 24px;
        border-radius: 24px;
    }

    .ContentPage-Content_type_video .ContentPage-Wrapper {
        flex-direction: column;
    }

    .ContentPage-ContentWrap {
        padding: 0;
    }

    .ContentPage-CategoryLink {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        margin-left: 0;
        margin-top: 24px;
        padding: 0 24px 0 16px;
    }

    .ContentPage-Video {
        margin-bottom: 0;
    }

    .ContentPage-ContentTextWrap {
        border-radius: 0;
        width: auto;
        position: relative;
        background-color: transparent;
        box-shadow: none;
    }

    .ContentPage-AdditionalBar {
        border: none;
    }

    .ContentPage-AdditionalBar_theme_dark {
        background-image: none;
    }

    .ContentPage-ContentAdditionalWrapper {
        margin: 0;
    }

    .ContentPage-ContentAdditionalTitle {
        margin-top: 0;
        text-align: center;
    }

    .ContentPage-AdditionalBar_theme_dark .ContentPage-ContentAdditionalTitle {
        display: none;
    }

    .ContentPage-type_pressa {
        color: var(--white);
    }

    .ContentPage-Title:before {
        fill: #fff;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
        content: '';
        display: block;
        width: 25px;
        height: 25px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        margin: 20px 5px 0px 5px;
    }

    .ContentPage_page_text .ContentPage-Wrapper {

    }

}

.Footer {
    font-family: "Nunito Regular", Arial, sans-serif;
    /*border-top: 20px solid #2D2D2D;*/
    background-color: var(--dark);
    color: var(--middleGrey);
    display: flex;
    justify-content: center;
    padding: 24px 0;
}

.Footer-OfferLink,
.Footer-OfferLink:hover,
.Footer-OfferLink:active,
.Footer-SubscriptionLink,
.Footer-SubscriptionLink:hover,
.Footer-SubscriptionLink:active {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 1.2;
    color: var(--white);
    display: block;
    margin: 0 0 24px 0;
}

.Footer p {
    margin: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-decoration: none;
}

.Footer-CategoriesLinkWrap {
    padding: 0 0 24px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 10px;
}

.Footer-CategoryLink {
    color: var(--middleGrey);
}

.Footer-CategoryLink:hover,
.Footer-CategoryLink:active {
    color: var(--green);
}

.Footer-Link {
    line-height: 40px;
    height: 40px;
    text-decoration: none;
    box-sizing: border-box;
    border-radius: 5px;
    text-align: center;
    align-items: center;
    padding: 0 14px 0 0;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
}

.Footer .TextLink,
.Footer .TextLink:hover,
.Footer .TextLink:active,
.Footer-Text a,
.Footer-Text a:hover,
.Footer-Text a:active {
    display: inline;
    color: #7A7A7A;
    -webkit-text-decoration: revert;
            text-decoration: revert;
}

.Footer-Text {
    font-size: 12px;
    color: #969696;
    text-align: justify;
    -webkit-hyphens: auto;
        -ms-hyphens: auto;
            hyphens: auto;
}

.Footer-LogoContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Footer-LogoContainer svg {
    height: 16px;
    width: 120px;
    color: var(--white);
}

.Footer-LogoContainer span {
    font-size: 12px;
    color: #7A7A7A;
}

.Footer-LogoLink,
.Footer-LogoLink:hover,
.Footer-LogoLink:active {
    height: 24px;
    margin-right: 10px;
    -webkit-filter: grayscale(1);
            filter: grayscale(1);
}

.Footer-Logo {
    height: 16px;
}

.Footer .HomeLink {
    text-decoration: none;
}

.Footer-Wrapper {
    display: flex;
    flex-direction: column;
    width: calc(100% - 48px);
}

.Footer-OfferLinks {
    display: flex;
    flex-direction: column;
}

.Footer-RightBlock,
.Footer-LeftBlock {
    flex: 1 1;
}


@media (min-width: 470px) {
    .Footer-CategoriesLinkWrap {
        flex-wrap: wrap;
        flex-direction: row;
    }

    .Footer-CategoryLink {
        margin-right: 10px;
    }

}


@media (min-width: 720px) {
    .Footer-OfferLinks {
        justify-content: space-between;
    }

    .Footer-Text {
        margin-top: 10px;
    }

    .Footer-LogoContainer {
        justify-content: space-between;
        margin: 0;
    }
}

@media (min-width: 1024px) {
    .Footer-Wrapper {
        max-width: 1272px;
        position: relative;
    }

    .Footer-SubscriptionLink,
    .Footer-SubscriptionLink:hover,
    .Footer-SubscriptionLink:active {
        margin-right: 50px;
    }

    .Footer-OfferLink:hover,
    .Footer-OfferLink:active,
    .Footer-SubscriptionLink:hover,
    .Footer-SubscriptionLink:active {
        color: var(--green);
    }

    .Footer-CategoryLink {
        line-height: 18px;
        margin-left: 24px;
        margin-right: 0;
        padding-right: 0;
    }

    .Footer-LogoContainer {
        max-width: 65%;
        justify-content: space-between;
        margin-top: 24px;
    }

    .Footer-LogoLink,
    .Footer-LogoLink:hover,
    .Footer-LogoLink:active {
        margin-right: 50px;
    }
}

@media (min-width: 1200px) {
    .Footer-OfferLinks {
        flex-direction: row;
    }

    .Footer-CategoriesLinkWrap {
        position: absolute;
        top: 0;
        right: 0;
        width: 250px;
        flex-wrap: wrap;
        justify-content: start;
        margin-top: 0;
        padding-bottom: 0;
    }

    .Footer_isPlayerOpen {
        padding-bottom: 60px;
    }

    .Footer-OfferLinks {
        display: flex;
        justify-content: flex-start;
    }

    .Footer-Text {
        margin-top: auto;
    }


}


.MusicItem {
    background: var(--lightViolet);
}

.MusicItem-Wrapper {
    display: flex;
    flex-direction: column;
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 24px 24px;
}

.MusicItem-Title {
    position: relative;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 36px;
    margin: 24px 0;
}

.MusicItem-ContentWrapper {
    display: grid;
    grid-gap: 24px;
    width: 100%;
    grid-template-columns: repeat(1,100%);
}

/*.MusicItem-Title:before {*/
    /*fill: #fff;*/
    /*position: absolute;*/
    /*top: 50%;*/
    /*transform: translateY(-50%);*/
    /*left: -30px;*/
    /*content: '';*/
    /*width: 25px;*/
    /*height: 25px;*/
    /*background-position: center;*/
    /*background-repeat: no-repeat;*/
    /*background-size: contain;*/
/*}*/

@media (min-width: 720px) {
    .MusicItem-Title {
        font-size: 36px;
    }

    .MusicItem-ContentWrapper {
        grid-template-columns: repeat(2, calc(50% - 12px));
    }
}

@media (min-width: 1024px) {
    .MusicItem-Wrapper {
        max-width: 1272px;
        padding: 0 24px 40px;
    }

    .MusicItem-Title {
        font-size: 44px;
        line-height: 48px;
        font-weight: 400;
    }

    .MusicItemPreview:nth-child(4) {
        display: none;
    }

    .MusicItem-ContentWrapper {
        grid-template-columns: repeat(3, calc(33% - 12px));
    }
}

@media (min-width: 1250px) {
    .MusicItemPreview:nth-child(4) {
        display: block;
    }

    .MusicItem-ContentWrapper {
        grid-template-columns: repeat(4, calc(25% - 18px));
    }
}

.CategoryList {

}


.MainPage {
}

.MainPage-Content {
    margin-top: 84px;
}

@media (max-width: 1200px) {
    .MainPage-Content {
    }
}

.OfferPage {
    flex-grow: 1;
}

.OfferPage-Text a{
    color: inherit;
    display: inline;
}

.OfferPage-Text {
    flex-grow: 1;
    max-width: 1300px;
    margin: 93px auto 0;
    text-align: justify;
    padding: 24px;
    font-family: "Nunito Regular", Arial, sans-serif;
}

.OfferPage-Text span {
    display: block;
    overflow-x: scroll;
}

.OfferPage-Text h2 {
    font-family: "Nunito Regular", Arial, sans-serif;
    text-align: left;
}

.OfferPage-Text li {
    margin-bottom: 10px;
}

.OfferPage-Text div {
    margin-bottom: 10px;
}

@media (min-width: 720px) {
    .OfferPage-Text span {
        overflow-x: auto;
    }
}

@media (max-width: 1200px) {
    .OfferPage-Text {
        margin-top: 84px;
    }
}

.Player {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 48px;
    width: 100%;
    background: rgba(255, 255, 255, 0.8);
    -webkit-backdrop-filter: blur(4px);
            backdrop-filter: blur(4px);
    padding: 0 5px 0 20px;
    z-index: 5;
    overflow: hidden;
}

.Player-Inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    max-width: 1440px;
    margin: 0 auto;
}

.Player-Preview {
    height: 28px;
    min-width: 28px;
    margin-right: 10px;
    border-radius: 4px;
}

.Player-PrevTrack, .Player-NextTrack, .Player-Play {
    border: none;
    background-color: inherit;
    outline: none;
}

.Player-PrevTrack:focus, .Player-NextTrack:focus, .Player-Play:focus {
    outline: none;
}

.Player-PrevTrack {
    width: 12px;
    height: 10px;
    margin: 0;
    padding: 0;
    font-size: 0;
}

.Player-NextTrack {
    width: 12px;
    height: 10px;
    margin: 0;
    padding: 0;
    font-size: 0;
}

.Player-Play {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Player-PrevTrack > svg,
.Player-NextTrack > svg,
.Player-Play > svg {
    color: var(--green);
}

.Player-Controls {
    min-width: 75px;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0 10px 0 5px;
}

.Player-TrackInfoInner {
    padding: 10px;
    height: 100%;
    overflow: hidden;
    position: relative;
    flex: 1 1;
}

.Player-TrackInfoInner:after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 15%;
    background: -webkit-gradient(linear, right top, left top, color-stop(60%, rgba(255, 255, 255, 1)), color-stop(70%, rgba(255, 255, 255, 0.8)), color-stop(80%, rgba(255, 255, 255, 0.6)), color-stop(90%, rgba(255, 255, 255, 0.2)), to(rgba(255, 255, 255, 0.1)));
    background: -webkit-linear-gradient(right, rgba(255, 255, 255, 1) 60%, rgba(255, 255, 255, 0.8) 70%, rgba(255, 255, 255, 0.6) 80%, rgba(255, 255, 255, 0.2) 90%, rgba(255, 255, 255, 0.1) 100%);
    background: linear-gradient(to left, rgba(255, 255, 255, 1) 60%, rgba(255, 255, 255, 0.8) 70%, rgba(255, 255, 255, 0.6) 80%, rgba(255, 255, 255, 0.2) 90%, rgba(255, 255, 255, 0.1) 100%);
    opacity: 0.9;
}

.Player-TrackName {
    font-size: 14px;
    margin-bottom: 5px;
    width: 100%;
}

.Player-PlayerName {
    font-size: 12px;
    color: #717171;
    width: 100%;
}

.Player-TrackNameInner, .Player-PlayerNameInner {
    display: inline-block;
    white-space: nowrap;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.Player-TrackNameInner.Player-TrackNameInner_isTicker,
.Player-PlayerNameInner.Player-PlayerNameInner_isTicker{
    -webkit-animation: tickerPlayer 5s infinite ease-in-out;
            animation: tickerPlayer 5s infinite ease-in-out;
}

@media (min-width: 1024px) {
    .Player-PrevTrack > svg:hover,
    .Player-NextTrack > svg:hover,
    .Player-Play > svg:hover {
        color: var(--purple);
    }
}



@-webkit-keyframes equalizerPlay1 {
    0% {
        height: 8px;
    }
    25% {
        height: 12px;
    }
    50% {
        height: 16px;
    }
    75% {
        height: 12px;
    }
    100% {
        height: 8px;
    }
}



@keyframes equalizerPlay1 {
    0% {
        height: 8px;
    }
    25% {
        height: 12px;
    }
    50% {
        height: 16px;
    }
    75% {
        height: 12px;
    }
    100% {
        height: 8px;
    }
}

@-webkit-keyframes equalizerPlay2 {
    0% {
        height: 16px;
    }
    25% {
        height: 12px;
    }
    50% {
        height: 8px;
    }
    75% {
        height: 12px;
    }
    100% {
        height: 16px;
    }
}

@keyframes equalizerPlay2 {
    0% {
        height: 16px;
    }
    25% {
        height: 12px;
    }
    50% {
        height: 8px;
    }
    75% {
        height: 12px;
    }
    100% {
        height: 16px;
    }
}

@-webkit-keyframes tickerPlayer {
    0% {
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0);
    }
    100% {
        -webkit-transform: translate(-120%, 0);
                transform: translate(-120%, 0);
    }
}

@keyframes tickerPlayer {
    0% {
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0);
    }
    100% {
        -webkit-transform: translate(-120%, 0);
                transform: translate(-120%, 0);
    }
}

.PressContentPage {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#111), to(#383838));
    background-image: -webkit-linear-gradient(top, #111 0%, #383838 100%);
    background-image: linear-gradient(180deg, #111 0%, #383838 100%);
    position: relative;
}

.PressContentPage-Header{
    display: none;
}

.PressContentPage .preloader {
    height: 69vh;
}

@media(min-height: 800px) {
    .PressContentPage .preloader {
        height: 59vh;
    }
}

.PressContentPage-ContentWrapper {
    height: calc(100vh - 142px);
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.PressContentPage * {
    box-sizing: border-box;
}

.PressContentPage-Item {
    flex-grow: 1;
    display: flex;
    align-items: center;
    max-height: 70px;
    min-height: 40px;
    padding-right: 24px;
}

.PressContentPage-ItemTitleWrapper {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: var(--white);
    font-size: 20px;
    position: relative;
}

.PressContentPage-ItemDate {
    position: relative;
    white-space: nowrap;
    margin-left: 10px;
    font-weight: 400;
    font-size: 16px;
}

.PressContentPage-ItemTitle {
    font-size: 18px;
}

.PressContentPage-CategoryLink,
.PressContentPage-CategoryLink:hover,
.PressContentPage-CategoryLink:active {
    text-decoration: none;
    display: block;
    height: 30px;
    width: 30px;
    position: absolute;
    top: 50%;
    left: 24px;
    z-index: 1;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    background-image: url(/static/media/back.12097767.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px 20px;
}

.PressContentPage-SwiperWrapper {
    position: relative;
    top: 4%
}

@media(min-height: 800px) {
    .PressContentPage-SwiperWrapper {
        top: 10%
    }
}

.PressContentPage-Paginator {
    color: var(--white);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    font-weight: normal;
    font-size: 20px;
    margin: 0;
    height: auto;
    padding: 10px 20px 10px 5px;
    line-height: 30px;
    background: rgba(0, 0, 0, 0.8);
    padding-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;

}

.PressContentPage-CurrentPageInput {
    height: 30px;
    color: var(--white);
    background-color: transparent;
    text-align: center;
    display: inline-block;
    border-radius: 5px;
    font-size: inherit;
    font-family: inherit;
    outline: none;
    cursor: pointer;
}

.PressContentPage-PaginatorText {
    margin: 0 8px;
    opacity: 1;
}

.PressContentPage .slide {
    width: auto;
    height: calc(100% - 50px);
    min-height: calc(100% - 50px);
}

.PressContentPage .swiper-button-next,
.PressContentPage .swiper-button-prev {
    display: none;
    top: 50%;
    background-color: var(--white);
    background-size: 40%;
}

.PressContentPage .swiper-button-next {
    right: 250px;
}

.PressContentPage .swiper-button-prev {
    left: 250px;
}


.PressContentPage .swiper-container,
.PressContentPage .swiper-wrapper {
    min-height: 100%;
}

.PressContentPage-Image {
    display: block;
    max-width: 100vw;
    margin: 0 auto;
    width: auto;
    height: auto;
}

@media(min-width: 720px) {
    .PressContentPage {
        position: static;
    }

    .PressContentPage-Item {
        margin: 0 auto;
    }

    .PressContentPage-SwiperWrapper {
        position: static;
    }

    .PressContentPage-Image {
        max-width: 720px !important;
        height: auto;
        width: 720px;
    }

    .PressContentPage-ContentWrapper {
        margin-top: 10px;
    }

    .PressContentPage-CategoryLink,
    .PressContentPage-CategoryLink:hover,
    .PressContentPage-CategoryLink:active {
        top: -5px;
        left: unset;
        -webkit-transform: unset;
                transform: unset;
        right: 120%;
    }

}

@media(min-width: 1024px) {
    /*.PressContentPage-SwiperWrapper {*/
    /*    flex-grow: 1;*/
    /*}*/
    /*.PressContentPage-ItemTitleWrapper{*/
    /*    justify-content: center;*/
    /*}*/

    .PressContentPage-SwiperWrapper {
        /*margin-left: 25%;*/
    }

    .PressContentPage .slide {
        /*width: auto !important;*/
        margin: auto;
    }

    .PressContentPage-Image {
        /*max-width: 820px !important;*/
        /*height: auto;*/
        /*width: 820px;*/
        height: calc(100vh - 134px);
        aspect-ratio: 0.762;
        max-width: unset !important;
        width: unset;
        /*margin: unset;*/
    }

    /*.PressContentPage .swiper-button-next,*/
    /*.PressContentPage .swiper-button-prev {*/
    /*    display: block;*/
    /*}*/
}

.SubscriptionPage {
    font-family: "Nunito Regular", Arial, sans-serif;
    flex-grow: 1;
    display: flex;
}

.SubscriptionPage-Text {
    flex-grow: 1;
    max-width: 1272px;
    margin: 84px 24px 24px;
}

.SubscriptionPage-Text a,
.SubscriptionPage-Text a:hover,
.SubscriptionPage-Text a:active {
    display: inline;
    color: inherit;
}

@media (min-width: 1024px) {
    .SubscriptionPage-Text {
        margin: 84px auto 24px;
    }
}

.App {
    min-width: 335px;
    /*max-width: 1320px;*/

    /*!*margin: 0 auto;*!*/
    /*min-height: 100vh;*/
}

.App-Wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

:root {
  --green: #00B956;
  --white: #ffffff;
  --lightGrey: #f6f6f6;
  --middleGrey: #999999;
  --grey: #6F7273;
  --dark: #333333;
  --black: #000;
  --purple: #731982;
  --lightBlue: #E1FAFF;
  --lightViolet: #EBEAFF;
  --lightRose: #FFEEFF;
  --rose: #FFC5C9;
  --lightGreen: #DDFFEC;
  --orange: #FFA717;
  --redOrange: #EB5A40;
}

@font-face {
  font-family: "Nunito Bold";
  font-style: normal;
  font-weight: 700;
  src: local("Nunito Bold"), url(/static/media/Nunito-Bold.ba43cdec.ttf) format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "Nunito Semibold";
  font-style: normal;
  font-weight: 600;
  src: local("Nunito Semibold"), url(/static/media/Nunito-SemiBold.38257ec3.ttf) format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "Nunito Medium";
  font-style: normal;
  font-weight: 500;
  src: local("Nunito Medium"), url(/static/media/Nunito-Medium.d26cecc9.ttf) format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "Nunito Regular";
  font-style: normal;
  font-weight: 400;
  src: local("Nunito Regular"), url(/static/media/Nunito-Regular.b83ce9c5.ttf) format("opentype");
  font-display: swap;
}

* {
  -webkit-tap-highlight-color: transparent !important;
  box-sizing: border-box;
}

html,
body {
  min-height: 100%;
}

body {
  padding: 0;
  font-family: "Nunito Regular", Arial, sans-serif;
  line-height: 1;
  overflow-x: hidden;
  min-width: 335px;
  color: #333333;
  color: var(--dark);
  margin: 0 auto;
  position: relative;
}

button,
a {
  cursor: pointer;
  display: block;
}

a {
  text-decoration: none;
}


/*swiper*/
.swiper-button-next,
.swiper-button-prev {
  background-image: url(/static/media/NextArrow.70db492d.svg);
  width: 60px;
  height: 60px;
  z-index: 999;
  background-position: center;
  border-radius: 30px;
  cursor: pointer;
  outline: none;
  top: 120px;
}

.swiper-scrollbar {
  height: 4px !important;
  max-width: 1400px !important;
  position: relative !important;
  left: 0 !important;
  background:rgba(255, 255, 255, 0.2);
  margin-top: 30px;
}

.swiper-button-prev {
  left: 80px;
  -webkit-transform: scale(-1, -1);
          transform: scale(-1, -1);
}
.swiper-button-next{
  right: 80px;
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
}

