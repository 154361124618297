.Menu {
    min-width: 320px;
    font-family: "Nunito Semibold", Arial, sans-serif;
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    width: 100%;
    margin-top: 84px;
    color: var(--white);
    min-height: 84px;
}

.Menu-Wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.Menu_visible {
    display: block;
}

.Menu-List {
    width: 100%;
    height: 100%;
    list-style: none;
    margin: 0;
    padding: 40px 0;
    background-color: var(--green);
}

.Menu-Link,
.Menu-Link:hover,
.Menu-Link:active {
    display: flex;
    justify-content: center;
    text-decoration: none;
    text-align: left;
    height: 48px;
    line-height: 48px;
    color: currentColor;
    position: relative;
}

/*.Menu-Item:before {*/
    /*position: absolute;*/
    /*top: 50%;*/
    /*transform: translateY(-50%);*/
    /*left: 0;*/
    /*content: '';*/
    /*display: block;*/
    /*width: 25px;*/
    /*height: 25px;*/
    /*background-position: center;*/
    /*background-repeat: no-repeat;*/
    /*background-size: contain;*/
/*}*/


@media (min-width: 720px) {
    .Menu {
        font-size: 24px;
    }

    .Menu-Link,
    .Menu-Link:hover,
    .Menu-Link:active {
        line-height: 90px;
        height: 90px;
    }
}


@media (min-width: 1200px) {
    .Menu {
        width: 100%;
        margin-top: 0;
        font-weight: 400;
        font-size: 14px;
    }

    .Menu-Wrapper {
        width: 900px;
        flex-direction: row;
        background-color: transparent;
        display: flex;
        align-items: center;
    }

    .Menu-List {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin: 0;
        padding: 0;
        height: 36px;
        background-color: transparent;
    }

    .Menu-Item {
        flex-grow: 1;
        padding: 0 15px;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .Menu-ItemLink {
        height: unset;
        line-height: unset;
        display: flex;
    }
    .Menu-ItemLink div {
        margin: auto;
    }

    .Menu-Item_active .Menu-ItemLink {
        margin: auto;
        padding: 4px 12px;
        border-radius: 18px;
        background-color: var(--purple);
        color: var(--white);
    }

    .Menu-Item_active .Menu-ItemLink div{
        /*color: var(--white);*/
    }

    .Menu-Item:before {
        display: none;
    }

    .Menu-Link,
    .Menu-Link:hover,
    .Menu-Link:active {
        color: var(--grey);
        justify-content: flex-end;
        height: unset;
        line-height: unset;
        /*height: 84px;*/
        /*line-height: 84px;*/
    }

    .Menu-Link:hover {
        color: var(--green);
    }

    .Menu-Item_active .Menu-Link,
    .Menu-Item_active .Menu-Link:hover,
    .Menu-Item_active .Menu-Link:active {
        color: var(--white);
    }


    .Menu-Item_active .Menu-ItemLink:hover {
        line-height: inherit;
    }

    .Menu-ItemLink-Text {
        font-style: normal;
        font-size: 14px;
        text-transform: none;
        /*line-height: 50px;*/
        text-align: center;
    }
}
