:root {
  --green: #00B956;
  --white: #ffffff;
  --lightGrey: #f6f6f6;
  --middleGrey: #999999;
  --grey: #6F7273;
  --dark: #333333;
  --black: #000;
  --purple: #731982;
  --lightBlue: #E1FAFF;
  --lightViolet: #EBEAFF;
  --lightRose: #FFEEFF;
  --rose: #FFC5C9;
  --lightGreen: #DDFFEC;
  --orange: #FFA717;
  --redOrange: #EB5A40;
}

@font-face {
  font-family: "Nunito Bold";
  font-style: normal;
  font-weight: 700;
  src: local("Nunito Bold"), url(./fonts/Nunito-Bold.ttf) format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "Nunito Semibold";
  font-style: normal;
  font-weight: 600;
  src: local("Nunito Semibold"), url(./fonts/Nunito-SemiBold.ttf) format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "Nunito Medium";
  font-style: normal;
  font-weight: 500;
  src: local("Nunito Medium"), url(./fonts/Nunito-Medium.ttf) format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "Nunito Regular";
  font-style: normal;
  font-weight: 400;
  src: local("Nunito Regular"), url(./fonts/Nunito-Regular.ttf) format("opentype");
  font-display: swap;
}

* {
  -webkit-tap-highlight-color: transparent !important;
  box-sizing: border-box;
}

html,
body {
  min-height: 100%;
}

body {
  padding: 0;
  font-family: "Nunito Regular", Arial, sans-serif;
  line-height: 1;
  overflow-x: hidden;
  min-width: 335px;
  color: var(--dark);
  margin: 0 auto;
  position: relative;
}

button,
a {
  cursor: pointer;
  display: block;
}

a {
  text-decoration: none;
}


/*swiper*/
.swiper-button-next,
.swiper-button-prev {
  background-image: url('./Components/Icons/NextArrow.svg');
  width: 60px;
  height: 60px;
  z-index: 999;
  background-position: center;
  border-radius: 30px;
  cursor: pointer;
  outline: none;
  top: 120px;
}

.swiper-scrollbar {
  height: 4px !important;
  max-width: 1400px !important;
  position: relative !important;
  left: 0 !important;
  background:rgba(255, 255, 255, 0.2);
  margin-top: 30px;
}

.swiper-button-prev {
  left: 80px;
  transform: scale(-1, -1);
}
.swiper-button-next{
  right: 80px;
  transform: scale(1, 1);
}
