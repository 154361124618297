.ContentItemPreview {
    border-radius: 6px;
    box-shadow: 0 12px 36px 0 rgba(0, 0, 0, 0.16);
    overflow: hidden;
}

.ContentItemPreview-ImageWrap {
    position: relative;
}

.ContentItemPreview-Link {
    padding: 24px;
    background: #fff;
}

.ContentItemPreview-Image {
    /*width: calc(100% - 96px);*/
    aspect-ratio: 1/1;
    border-radius: 6px;
    position: relative;
}
.ContentItemPreview-PlayIcon {
    width: 60px;
    height: 60px;
    border-radius: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: url('../Icons/play-button.svg');
    background-position: 20px 17px;
    background-repeat: no-repeat;
}

.ContentItemPreview-ButtonWrapper {
    position: absolute;
    bottom: 12px;
    width: calc(100% - 24px);
    left: 12px;
    display: flex;
    justify-content: space-between;
    z-index: 5;
}

.ContentItemPreview-ButtonWrapper_size_small {
    position: static;
    width: 100%;
    margin-top: 12px;
}

.ContentItemPreview-Cart {
     content: '';
     display: flex;
     width: 36px;
     height: 36px;
     background-color: var(--orange);
     border-radius: 6px;
     z-index: 5;
}

.ContentItemPreview-Cart > svg {
    margin: auto;
    color: var(--white)
}

.ContentItemPreview-Button {
    width: fit-content;
    /*min-width: 140px;*/
    height: 36px;
    background-color: var(--green);
    /*background-image: url('../Icons/youtube-alt.svg');*/
    /*background-repeat: no-repeat;*/
    /*background-size: 26px;*/
    /*background-position: 6px center;*/
    color: #fff;
    font-size: 12px;
    line-height: 36px;
    border-radius: 6px;
    display: flex;
}

.ContentItemPreview-DescriptionWrap {
    flex-grow: 1;
}

.ContentItemPreview-ButtonText {
    width: fit-content;
    margin: auto;
    padding: 0 12px 0 36px;
    background-image: url('../Icons/youtube-alt.svg');
    background-repeat: no-repeat;
    background-size: 26px;
    background-position: 6px center;
}

.ContentItemPreview-Button_type_read .ContentItemPreview-ButtonText {
    background-image: url('../Icons/file-alt.svg');
}

/*стили для size_small*/

.ContentItemPreview_size_small {

}

.ContentItemPreview_size_small .ContentItemPreview-Link {
    padding: 12px;
    /*position: relative;*/
}

.ContentItemPreview_size_small .ContentItemPreview-Info {
    display: flex;
    overflow: hidden;
    position: relative;
}

.ContentItemPreview_size_small .ContentItemPreview-ImageWrap {
    width: 30%;
    min-width: 100px;
    min-height: 100px;
    margin-right: 12px;
    position: static;
}

.ContentItemPreview_size_small .ContentItemPreview-Button{
    flex-grow: 1;
}

.ContentItemPreview_size_small .ContentItemPreview-Cart {
    margin-left: 12px;
}

.ContentItemPreview_size_small .ContentItemPreview-Cart{
    right: 0;
    bottom: 0;
}

@media (min-width: 360px) {
    .ContentItemPreview_size_small .ContentItemPreview-Button{
        flex-grow: 1;
        left: 112px;
    }

    .ContentItemPreview_size_small .ContentItemPreview-Cart {
        margin-left: 12px;
    }

    .ContentItemPreview_size_small .ContentItemPreview-Button_type_read{
        width: 60%;
        display: flex;
        justify-content: center;
        background-position: 30% center;
    }
}

/*стили для странцы контента*/
.ContentItemPreview_size_common .ContentItemPreview-Info {
    display: flex;
    flex-direction: column;
}

.ContentItemPreview_size_common .ContentItemPreview-ImageWrap {
    order: 1;
}

@media (min-width: 1200px) {
    .ContentItemPreview:hover .ContentItemPreview-PlayIcon {
        background-color: var(--purple);
    }

    .ContentItemPreview:hover .ContentItemPreview-Cart {
        background-color: var(--redOrange);
    }

    .ContentItemPreview:hover .ContentItemPreview-Button {
        background-color: var(--purple);
    }
}
