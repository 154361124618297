.MusicItem {
    background: var(--lightViolet);
}

.MusicItem-Wrapper {
    display: flex;
    flex-direction: column;
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 24px 24px;
}

.MusicItem-Title {
    position: relative;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 36px;
    margin: 24px 0;
}

.MusicItem-ContentWrapper {
    display: grid;
    grid-gap: 24px;
    width: 100%;
    grid-template-columns: repeat(1,100%);
}

/*.MusicItem-Title:before {*/
    /*fill: #fff;*/
    /*position: absolute;*/
    /*top: 50%;*/
    /*transform: translateY(-50%);*/
    /*left: -30px;*/
    /*content: '';*/
    /*width: 25px;*/
    /*height: 25px;*/
    /*background-position: center;*/
    /*background-repeat: no-repeat;*/
    /*background-size: contain;*/
/*}*/

@media (min-width: 720px) {
    .MusicItem-Title {
        font-size: 36px;
    }

    .MusicItem-ContentWrapper {
        grid-template-columns: repeat(2, calc(50% - 12px));
    }
}

@media (min-width: 1024px) {
    .MusicItem-Wrapper {
        max-width: 1272px;
        padding: 0 24px 40px;
    }

    .MusicItem-Title {
        font-size: 44px;
        line-height: 48px;
        font-weight: 400;
    }

    .MusicItemPreview:nth-child(4) {
        display: none;
    }

    .MusicItem-ContentWrapper {
        grid-template-columns: repeat(3, calc(33% - 12px));
    }
}

@media (min-width: 1250px) {
    .MusicItemPreview:nth-child(4) {
        display: block;
    }

    .MusicItem-ContentWrapper {
        grid-template-columns: repeat(4, calc(25% - 18px));
    }
}
