.ContentDescription {
    position: relative;
    font-family: "Nunito Regular", Arial,sans-serif;
    color: #333333;
    max-height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.ContentDescription_size_small {
    color: #58595b;
    margin: 0;
    margin-top: -2px;
}

.ContentDescription-Title {
    color: #969696;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 40px;
    position: relative;
    font-family: "Nunito Regular", Arial, sans-serif;
}

.ContentDescription-Tags,
.ContentDescription-Type {
    font-family: "Nunito Regular", sans-serif;
    font-style: normal;
    font-weight: 700;
    height: 22px;
    margin-left: 0;
    margin-bottom: 8px;
    font-size: 18px;
    line-height: 22px;
    color: #000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ContentDescription-TitleText {
    font-family: 'Nunito Regular', sans-serif;
}

.ContentDescription-Type {
    display: none;
}

.ContentDescription_size_small .ContentDescription-LabelWrap {
    display: none;
}

.ContentDescription_size_small .ContentDescription-Title {
    height: 54px;
}

    /*стили для контента на странице контента*/
.ContentDescription_ofPage_ContentPage.ContentDescription_size_common .ContentDescription-Title{
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 20px;
}


@media (min-width: 375px) {
    .ContentDescription {
        width: 100%;
    }
}
